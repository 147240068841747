// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-authors-js": () => import("./../../../src/pages/blog_authors.js" /* webpackChunkName: "component---src-pages-blog-authors-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partnerships-embed-js": () => import("./../../../src/pages/partnerships-embed.js" /* webpackChunkName: "component---src-pages-partnerships-embed-js" */),
  "component---src-pages-partnerships-js": () => import("./../../../src/pages/partnerships.js" /* webpackChunkName: "component---src-pages-partnerships-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-solutions-benefits-js": () => import("./../../../src/pages/solutions/benefits.js" /* webpackChunkName: "component---src-pages-solutions-benefits-js" */),
  "component---src-pages-solutions-index-js": () => import("./../../../src/pages/solutions/index.js" /* webpackChunkName: "component---src-pages-solutions-index-js" */),
  "component---src-pages-solutions-organization-js": () => import("./../../../src/pages/solutions/organization.js" /* webpackChunkName: "component---src-pages-solutions-organization-js" */),
  "component---src-pages-solutions-outsourcing-js": () => import("./../../../src/pages/solutions/outsourcing.js" /* webpackChunkName: "component---src-pages-solutions-outsourcing-js" */),
  "component---src-pages-solutions-predictive-ai-js": () => import("./../../../src/pages/solutions/predictive-ai.js" /* webpackChunkName: "component---src-pages-solutions-predictive-ai-js" */),
  "component---src-pages-solutions-predictive-assessments-js": () => import("./../../../src/pages/solutions/predictive_assessments.js" /* webpackChunkName: "component---src-pages-solutions-predictive-assessments-js" */),
  "component---src-pages-solutions-restorativ-tools-js": () => import("./../../../src/pages/solutions/restorativ_tools.js" /* webpackChunkName: "component---src-pages-solutions-restorativ-tools-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-what-is-rj-js": () => import("./../../../src/pages/what-is-rj.js" /* webpackChunkName: "component---src-pages-what-is-rj-js" */),
  "component---src-templates-archive-blog-archive-js": () => import("./../../../src/templates/archive/blog_archive.js" /* webpackChunkName: "component---src-templates-archive-blog-archive-js" */),
  "component---src-templates-post-post-js": () => import("./../../../src/templates/post/post.js" /* webpackChunkName: "component---src-templates-post-post-js" */),
  "component---src-templates-profile-profile-js": () => import("./../../../src/templates/profile/profile.js" /* webpackChunkName: "component---src-templates-profile-profile-js" */),
  "component---src-templates-tab-tab-js": () => import("./../../../src/templates/tab/tab.js" /* webpackChunkName: "component---src-templates-tab-tab-js" */)
}

